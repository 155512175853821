<template>
  <b-container>
    <b-row class="save-action">
      <file-icon size="1x" class="mr-1" />
      <a @click="importGameSaveFileWrapper" class="card-link" role="button">
        从本地导入存档
      </a>
    </b-row>
    <b-row class="mt-1 save-action">
      <download-cloud-icon size="1x" class="mr-1" />
      <a
        @click="show_cloud_import = !show_cloud_import"
        class="card-link"
        role="button"
      >
        从云端导入存档
      </a>
    </b-row>
    <b-row class="mt-2 save-action" v-if="show_cloud_import">
      <b-col sm="9">
        <b-form-input v-model="save_hash" placeholder="请输入存档 ID" />
      </b-col>
      <b-col sm="3">
        <b-button
          @click="importGameSaveFromCloud(save_hash)"
          variant="outline-primary"
        >
          导入
        </b-button>
      </b-col>
    </b-row>
    <b-row v-if="loading_cloud_import" class="mt-1 save-action">
      <b-spinner small variant="primary" class="mr-1" />
      导入中，请稍后...
    </b-row>
  </b-container>
</template>

<script>
import { FileIcon, DownloadCloudIcon } from "vue-feather-icons";
import importGameSave from "@/utils/save/importGameSave";
import importGameSaveFromFile from "@/utils/save/importGameSaveFromFile";
import { fileOpen } from "browser-fs-access";

export default {
  name: "ImportSaveModal",
  components: {
    FileIcon,
    DownloadCloudIcon,
  },
  inject: ["save_key"],
  data: function () {
    return {
      save_hash: "",
      show_cloud_import: false,
      loading_cloud_import: false,
    };
  },
  methods: {
    importGameSaveFromCloud: async function (save_hash) {
      this.show_cloud_import = false;
      this.loading_cloud_import = true;
      await this.$nextTick();
      try {
        const response = await fetch(
          `https://api-dos.zczc.cz/saves/${save_hash}`
        );
        const save_result = await response.json();
        if (save_result.code == 200) {
          const importObject = save_result.save;
          importGameSave(importObject);
          window.disable_beforeunload = true;
          location.reload();
        } else {
          throw new Error(save_result.message);
        }
      } catch (e) {
        this.$bvToast.toast(`从云端导入存档失败，请再次尝试。${e}`, {
          title: "导入存档失败❌",
          autoHideDelay: 5000,
          variant: "danger",
          solid: true,
        });
      } finally {
        this.loading_cloud_import = false;
      }
    },
    importGameSaveFileWrapper: async function () {
        const file = await fileOpen({
          extensions: [".json", ".zip"],
          description: '选择存档文件',
          id: "import-save-file",
        });

        const save_key = this.save_key;

        try {
          await importGameSaveFromFile(file, save_key);
          window.disable_beforeunload = true;
          location.reload();
        } catch (e) {
          this.$bvToast.toast(`从本地导入存档失败，请再次尝试。${e}`, {
            title: "导入存档失败❌",
            autoHideDelay: 5000,
            variant: "danger",
            solid: true,
          });
        }
    },
  },
};
</script>

<style scoped>
.save-action {
  align-items: center;
}
</style>
